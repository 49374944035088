
import Vue, { PropType } from 'vue'
import { MetaInfo } from 'vue-meta'
import { Models } from '~/types/models'

export default Vue.extend({
    name: 'CTAEmail',
    props: {
        cta: {
            type: Object as PropType<Models.CtaCard>,
            required: true,
        },
    },
    data() {
        return {
            hide: false,
            form: {
                data: {
                    email: '' as string,
                    agree: false as boolean,
                },
                error: {
                    email: '' as string,
                    agree: false as boolean,
                },
            },
            subscribing: false,
        }
    },
    head(): MetaInfo {
        const image = this.$assets.illustration.cta.email
        const image2 = '/assets/svg/bg-email.svg'
        return {
            link: [
                {
                    rel: 'preload',
                    as: 'image',
                    href: image,
                    media: !(this.cta.mobile && this.cta.desktop)
                        ? this.cta.mobile
                            ? '(max-width: 1023px)'
                            : this.cta.desktop
                            ? '(min-width: 1024px)'
                            : ''
                        : '',
                },
                {
                    rel: 'preload',
                    as: 'image',
                    href: image2,
                    media: !(this.cta.mobile && this.cta.desktop)
                        ? this.cta.mobile
                            ? '(max-width: 1023px)'
                            : this.cta.desktop
                            ? '(min-width: 1024px)'
                            : ''
                        : '',
                },
            ],
        } as MetaInfo
    },
    methods: {
        hideCard() {
            this.hide = true
        },
        async newSubscription() {
            if (!this.subscribing) {
                if (this.form.data.agree === false) {
                    this.form.error.agree = true
                } else {
                    this.form.error.agree = false
                }
                if (this.form.data.email.length === 0) {
                    this.form.error.email = this.$util.lang.components.ctaEMail.insertEmail
                } else {
                    this.form.error.email = ''
                }
                if (this.form.error.agree || this.form.error.email.length > 0) {
                    return
                }

                try {
                    this.subscribing = true

                    const result = await this.$api.general.newsletterSubscription(this.form.data)

                    if (result.feedback === 'data_success') {
                        this.form.data.email = ''
                        this.form.error.email = ''
                        this.form.data.agree = false
                        this.form.error.agree = false
                        this.$root.$emit('notification', {
                            text: result.data.message || this.$util.lang.components.ctaEMail.success,
                            type: 'success',
                        })
                    } else if (result.feedback === 'existing_email') {
                        this.$root.$emit('notification', {
                            text: result.data.message || this.$util.lang.components.ctaEMail.failed,
                            type: 'error',
                        })
                    } else if (result.feedback === 'params_validation_failed') {
                        this.form.error.email =
                            result.data.email[0] || this.$util.lang.components.ctaEMail.error
                    } else if (result.feedback === 'error_mail') {
                        this.form.error.email =
                            result.data.message || this.$util.lang.components.ctaEMail.error
                    }

                    this.subscribing = false
                } catch (e: any) {}
            }
        },
    },
})
