
import Vue from 'vue'

export default Vue.extend({
    name: 'FormFieldCheckbox',
    props: {
        value: Boolean,
        label: String,
        id: String,
        invalid: Boolean,
    },
    methods: {
        setValue(value: boolean) {
            ;(this.$refs.input as HTMLInputElement).checked = value

            this.$emit('input', value)
        },
    },
})
