
import Vue, { PropType } from 'vue'
import { MetaInfo } from 'vue-meta/types'
import { Models } from '~/types/models'

export default Vue.extend({
    name: 'SidebarBanners',
    props: {
        bannerPos: Array as PropType<number[]>,
    },
    head() {
        return {
            link: this.$store.state.layout?.sidebar?.banners.map((item: any) => ({
                rel: 'preload',
                href: item.image.url,
                as: 'image',
                type: 'image/jpg',
                media: '(min-width: 1024px)',
            })),
        } as MetaInfo
    },
    computed: {
        banners(): Models.SidebarBanner[] {
            return this.$store.state.layout?.sidebar?.banners
        },
        bannerTop(): Models.SidebarBanner | undefined {
            return this.banners?.length && this.bannerPos[0]
                ? this.banners.find((banner) => banner.order === this.bannerPos[0])
                : undefined
        },
        bannerBottom(): Models.SidebarBanner | undefined {
            return this.banners?.length && this.bannerPos[1]
                ? this.banners.find((banner) => banner.order === this.bannerPos[1])
                : undefined
        },
    },
})
