
import Vue, { PropType } from 'vue'

export default Vue.extend({
    name: 'BtnSeeMoreResults',
    props: {
        handler: {
            type: Function as PropType<() => any>,
        },
        loading: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
    },
})
