
import Vue from 'vue'

export default Vue.extend({
    name: 'DropdownOrderby',
    props: {
        options: {
            type: Object as () => {
                data: Array<string>
                selected: string
            },
        },
        width: {
            type: String,
            default: '',
        },
    },
    data() {
        return {}
    },
    methods: {
        emitChangeSelected(newSelected: any) {
            const newoptions = this.options
            newoptions.selected = newSelected
            this.$emit('update:options', newoptions)
        },
    },
})
