
import Vue, { PropOptions } from 'vue'
import { Models } from '~/types/models'
import { defaultImage } from '~/libs/useful-functions'

export default Vue.extend({
    name: 'SidebarFeaturedCoupons',
    props: {
        discounts: { type: Array } as PropOptions<Models.Discount[]>,
    },
    data() {
        return {
            defaultImage,
        }
    },
})
