
import Vue from 'vue'
import { WithContext } from 'schema-dts'
import { MetaInfo } from 'vue-meta/types'
import { Api } from '~/global'
import { currentDate } from '~/libs/date-functions'
import { Helpers } from '~/types/helpers'
import { Models } from '~/types/models'

export default Vue.extend({
    name: 'Home',
    async asyncData({ $api, store, route }) {
        const pageData = await $api.pages.loadHome()

        store.commit('pages/setHome', {
            key: 'index',
            value: pageData,
        })

        if (route.name === 'index-comentados' && !store.state.pages.home.commented) {
            const tabData = await $api.pages.loadHomeCommented()

            store.commit('pages/setHome', {
                key: 'commented',
                value: tabData.data.most_comment_discounts,
            })
        }

        if (route.name === 'index-nuevos' && !store.state.pages.home.latest) {
            const tabData = await $api.pages.loadHomeLatest()

            store.commit('pages/setHome', {
                key: 'latest',
                value: tabData.data.new_discounts,
            })
        }
    },
    data() {
        const info = this.$store.state.pages.home.index as Api.Responses.Pages.Home

        const latest = this.$store.state.pages.home
            .latest as Api.Responses.Pages.HomeLatest['data']['new_discounts']

        const commented = this.$store.state.pages.home
            .commented as Api.Responses.Pages.HomeCommented['data']['most_comment_discounts']

        let tabData: any[] = []

        let tabMeta: Helpers.Meta | null = null

        if (this.$route.name === 'index' && info) {
            tabData = [...info.page.data.popular_discounts.data]
            tabMeta = {
                ...info.page.data.popular_discounts.meta,
            }
        }
        if (this.$route.name === 'index-nuevos' && latest) {
            tabData = [...latest.data]
            tabMeta = {
                ...latest.meta,
            }
        }
        if (this.$route.name === 'index-comentados' && commented) {
            tabData = [...commented.data]
            tabMeta = {
                ...commented.meta,
            }
        }

        if (info.page.cta_cards.length) {
            info.page.cta_cards.forEach((cta) => {
                tabData.splice(cta.position - 1, 0, { ...cta, isCtaCard: true })
            })
        }

        const currentDates = currentDate()

        const [title, description] = this.$util.replacePlaceholders(
            [
                [':month', currentDates.month || ''],
                [':day', String(currentDates.day) || ''],
                [':year', String(currentDates.year) || ''],
                [':site', process.env.SITE_NAME || ''],
            ],
            [info?.page.title || '', info?.page.description || '']
        )

        return {
            info,
            ranking: {
                monthly: null as null | Models.User[],
                weekly: null as null | Models.User[],
            },
            metaInfo: { title, description },
            tabData,
            tabMeta,
            tabsInfo: {
                show: this.$route.name === 'index' ? 0 : this.$route.name === 'index-nuevos' ? 1 : 2,
                tabs: [
                    { to: '/', title: this.$util.lang.views.homepage.popular },
                    {
                        to: `/${this.$util.lang.routes.news}`,
                        title: this.$util.lang.views.homepage.news,
                    },
                    {
                        to: `/${this.$util.lang.routes.commented}`,
                        title: this.$util.lang.views.homepage.commented,
                    },
                ],
            },
            fetchButton: {
                loading: false,
                show:
                    tabMeta?.total &&
                    tabMeta?.current_page &&
                    tabMeta?.last_page &&
                    tabMeta?.total > 0 &&
                    tabMeta?.current_page < tabMeta?.last_page,
            },
            currentDates,
            params: {
                page: 2,
            },
            showBanner: true,
        }
    },
    head(): MetaInfo {
        return {
            link: [
                {
                    rel: 'canonical',
                    href: this.$util.host + this.$route.path,
                },
            ],
            ...this.$util.metaInit(
                [
                    ['description', this.metaInfo?.description],
                    ['title', this.metaInfo?.title],
                    ['og:url', this.$util.host + this.$route.path],
                    ['og:title', this.metaInfo?.title],
                    ['og:image:alt', this.metaInfo?.title],
                    ['og:description', this.metaInfo?.description],
                    ...(this.$route.name !== 'index' ? [['robots', 'noindex'] as [string, string]] : []),
                ],
                this.metaInfo?.title
            ),
        }
    },
    jsonld(): WithContext<any> {
        return {
            '@context': 'http://schema.org',
            '@graph': [
                {
                    '@context': 'https://schema.org',
                    '@type': 'WebSite',
                    name: 'Bchollos',
                    url: 'https://www.bchollos.es',
                },
                {
                    '@context': 'https://schema.org',
                    '@type': 'Organization',
                    name: 'Bchollos',
                    url: 'https://www.bchollos.es',
                    brand: 'Bchollos',
                    logo: 'https://www.bchollos.es/assets/schema.org/organisation/bchollos.png',
                    email: 'Bchollos@hurtadodeals.com',
                    founder: 'Juan Hurtado',
                    address: 'Avenida Nuevo León 161, 2 piso, Hipódromo, Ciudad de México, México, 06100',
                    sameAs: [
                        'https://www.instagram.com/megadescuentosmx/',
                        'https://twitter.com/megadescuentosm',
                        'https://www.youtube.com/channel/UCaL6vzKyHpO7mu6gbu47Z9g',
                        'https://www.tiktok.com/@megadescuentosm',
                        'https://www.facebook.com/megadescuentosm/',
                        'https://www.linkedin.com/company/81934995/admin/',
                    ],
                },
            ],
        }
    },
    computed: {
        featuredCoupons(): Models.Discount[] | null {
            return this.$store.state.layout?.sidebar?.coupons || null
        },
        categories(): any {
            return this.$store.state.layout?.sidebar?.categories || null
        },
        stores(): any {
            return this.$store.state.layout?.sidebar?.stores || null
        },
    },
    mounted() {
        setTimeout(() => {
            this.$nextTick(() => {
                this.info.page.banners && this.$store.dispatch('assignBanners', this.info.page.banners)
                this.$api.pages.loadHomeFeatured().then((res: any) => {
                    if (res && res.error === false) {
                        this.ranking.monthly = res.data.ranking_monthly
                        this.ranking.weekly = res.data.ranking_weekly
                    }
                })
            })
        }, 2000)
    },
    methods: {
        async loadMoreHome() {
            this.fetchButton.loading = true

            if (this.$route.name === 'index') {
                const result = (await this.$api.pages.loadHome(this.params)) as Api.Responses.Pages.Home

                if (result?.feedback === 'data_success') {
                    this.tabData = [...this.tabData, ...result.page.data.popular_discounts.data]

                    const { current_page, last_page } = result.page.data.popular_discounts.meta

                    if (!last_page || current_page === last_page) {
                        this.fetchButton.show = false
                    } else {
                        this.params.page = current_page + 1
                    }
                }
            }

            if (this.$route.name === 'index-comentados') {
                const result = (await this.$api.pages.loadHomeCommented(
                    this.params
                )) as Api.Responses.Pages.HomeCommented

                if (result?.feedback === 'data_success') {
                    this.tabData = [...this.tabData, ...result.data.most_comment_discounts.data]

                    const { current_page, last_page } = result.data.most_comment_discounts.meta

                    if (!last_page || current_page === last_page) {
                        this.fetchButton.show = false
                    } else {
                        this.params.page = current_page + 1
                    }
                }
            }

            if (this.$route.name === 'index-nuevos') {
                const result = (await this.$api.pages.loadHomeLatest(
                    this.params
                )) as Api.Responses.Pages.HomeLatest

                if (result?.feedback === 'data_success') {
                    this.tabData = [...this.tabData, ...result.data.new_discounts.data]

                    const { current_page, last_page } = result.data.new_discounts.meta

                    if (!last_page || current_page === last_page) {
                        this.fetchButton.show = false
                    } else {
                        this.params.page = current_page + 1
                    }
                }
            }

            this.fetchButton.loading = false
        },
        closeBanner() {
            this.showBanner = false
        },
    },
})
